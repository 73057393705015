import PageHeader from 'components/shared/PageHeader';
import PageTitle from 'components/shared/PageTitle';
import styled from 'styled-components';
import { useState } from 'react';
import NewOrganizationModal from './NewOrgModal';

function OrgPageHeader({ onAddOrganization }) {
    const [modalIsOpen, setModalIsOpen] = useState(false);
  
    const toggleModal = () => {
      setModalIsOpen(!modalIsOpen);
    };
  
    return (
      <div style={{marginLeft: "-180px", width: "121%"  }}>
        <PageHeader>
          <PageTitle>Organizations</PageTitle>
          {/* <NewOrganizationButton onClick={toggleModal}>New Organization</NewOrganizationButton> */}
        </PageHeader>
        {/* <NewOrganizationModal isOpen={modalIsOpen} onClose={toggleModal} onAddOrganization={onAddOrganization} /> */}
      </div>
    );
  }

  
const NewOrganizationButton = styled.button`
padding: 10px 18px;
font-size: 20px;
font-family: Ubuntu, sans-serif;
color: var(--bicollab-black-white-white, #fff);
background-color: var(--bicollab-secondary-color-orange-700, #f16622);
border: none;
border-radius: 4px;
cursor: pointer;
`;

export default OrgPageHeader;