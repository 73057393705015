import styled from "styled-components";
import useFetchWithMsal from 'hooks/useFetchWithMsal';
import { protectedResources } from 'config/msalConfig';
import React, { useState } from 'react';



const OrgPageSearch = ({ setSearchResults }) => {

    const [organization, setOrganization] = useState('');
    const { execute } = useFetchWithMsal({
      scopes: protectedResources.api.scopes.read,
    });
  
    const handleSearch = () => {
      console.log('Sending search parameters:', { organization });
  
      execute("GET", `${protectedResources.api.baseEndPoint}/organizations?${new URLSearchParams({ organization })}`).then((response) => {
        // Handle the response data as needed
        setSearchResults(response.data);
        console.log('Search Results:', response.data);
      }).catch((error) => {
        console.error('Error fetching data:', error);
      });
    };
  
    const handleOrganizationChange = (e) => {
      console.log('Event object:', e);
      console.log('Customer input:', e.target.value);
      setOrganization(e.target.value);
    };
  
    return (
      <SearchParameterContainer>
        <SearchParameterTitle>Search Parameter</SearchParameterTitle>
        <SearchParameterFields>
          <InputField
            label="Organization"
            placeholder="Enter a name or email address"
            value={organization}
            onChange={handleOrganizationChange}
          />
          <SearchButton onClick={handleSearch}>Search</SearchButton>
        </SearchParameterFields>
      </SearchParameterContainer>
    );
  };

  

function SelectField({ label, options, placeholder, onChange }) {
    const handleSelectChange = (event) => {
      onChange(event.target.value);
    };
  
    return (
      <SelectFieldContainer>
        <SelectFieldLabel>{label}</SelectFieldLabel>
        <SelectWrapper>
          <select onChange={handleSelectChange}>
            <option value="">{placeholder}</option>
            {options.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </SelectWrapper>
      </SelectFieldContainer>
    );
  }
  
  
  function InputField({ label, placeholder, value, onChange }) {
    return (
      <InputFieldContainer>
        <InputFieldLabel>{label}</InputFieldLabel>
        <InputFieldWrapper
          type="text"  // Specify the type if necessary, or make it a prop if you need different types
          placeholder={placeholder}
          value={value}  // Bind the input value to the value prop
          onChange={onChange}  // Propagate the onChange event to the parent component
        />
      </InputFieldContainer>
    );
  }
  
  
  const SearchParameterContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: stretch; // Ensures that child components stretch to fill the container width
  border: 1px solid #BBBBBC;
  background-color: #F2F2F2;
  padding: 20px;
  margin-left: -155px;
  width: 125%;
  height: 25%;
  `;
  
  
  const SearchParameterTitle = styled.h2`
  color: var(--bicollab-primary-color-blue-900, #002c57);
  letter-spacing: 0.4px;
  font: 500 16px/100% Ubuntu, sans-serif;
  
  @media (max-width: 991px) {
    max-width: 100%;
  }
  `;
  
  
  const SearchParameterFields = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center; // Make sure all elements are vertically aligned
  gap: 10px;
  margin-top: 24px;
  
  `;
  


const SelectWrapper = styled.div`
position: relative;
display: flex;
align-items: center;
background-color: #FFFFFF;
border: 1px solid #BBBBBC;
border-radius: 2px;
padding: 10px 12px;
width: 100%; // Ensures the select fills the container

select {
  border: none;
  width: 100%;
  padding-right: 30px; // Make room for the icon
  -moz-appearance: none; // Remove default style Mozilla
  -webkit-appearance: none; // Remove default style Chrome and Safari
  appearance: none; // Remove default style
  background: none;
  font-family: Ubuntu, sans-serif;
  font-size: 16px;
  color: #767676;
}

&:after {
  content: '▼'; // Adds a custom dropdown icon
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #767676;
  pointer-events: none; // Makes sure clicks on the icon interact with the select
}
`;




const SelectFieldLabel = styled.label`
color: #767676;
text-align: left;
font: 500 14px Ubuntu, sans-serif;
 
`;

const SelectFieldWrapper = styled.div`
display: flex;
justify-content: center;
align-items: center;
gap: 10px;
border-radius: 2px;
border: 1px solid rgba(187, 187, 188, 1);
background-color: var(--bicollab-black-white-white, #fff);
margin-top: 13px;
padding: 10px 21px;
font-size: 16px;
color: var(--bicollab-black-white-black-300, #bbbbbc);
font-weight: 400;

@media (max-width: 991px) {
  white-space: initial;
  padding: 0 20px;
}
`;

const SelectFieldText = styled.span`
font-family: Ubuntu, sans-serif;
margin: auto 0;
`;

const SelectFieldIcon = styled.img`
width: 24px;
aspect-ratio: 1;
object-fit: contain;
object-position: center;
`;

const InputFieldLabel = styled.label`
color: #767676;
text-align: left;
font: 500 14px Ubuntu, sans-serif;
`;

const InputFieldWrapper = styled.input`
border-radius: 2px;
border: 1px solid rgba(187, 187, 188, 1);
background-color: var(--bicollab-black-white-white, #fff);
margin-top: 13px;
padding: 10px 21px;
color: var(--bicollab-black-white-black-300, #bbbbbc);
font: 400 16px Ubuntu, sans-serif;

@media (max-width: 991px) {
  padding: 0 20px;
}
`;

const SelectFieldContainer = styled.div`
flex: 1; // Takes an equal portion of the available space
display: flex;
flex-direction: column;
padding: 0 5px; // Give some space around the fields
`;

const InputFieldContainer = styled.div`
flex: 1; // Equal flex distribution
display: flex;
flex-direction: column;
padding: 0 5px; // Consistent padding with SelectField
`;



const SearchButton = styled.button`
border-radius: 4px;
background-color: var(--bicollab-secondary-color-orange-700, #f16622);
align-self: flex-end;
margin-top: 28px;
padding: 10px 18px;
color: var(--bicollab-black-white-white, #fff);
white-space: nowrap;
font: 500 20px Ubuntu, sans-serif;

@media (max-width: 991px) {
  white-space: initial;
  padding: 0 20px;
}
`;

  export default OrgPageSearch;