import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import useFetchWithMsal from 'hooks/useFetchWithMsal';
import { protectedResources } from 'config/msalConfig';


// Styled components for the modal
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1050;
`;

const ModalContainer = styled.div`
  background: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 500px;
  z-index: 1051;
`;

const ModalHeader = styled.h2`
  margin-top: 0;
`;

const ModalForm = styled.form`
  display: flex;
  flex-direction: column;
`;

const FormLabel = styled.label`
  margin-top: 10px;
`;

const FormInput = styled.input`
  padding: 8px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const ButtonsRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

const Button = styled.button`
  padding: 10px 20px;
  border-radius: 4px;
  border: none;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  &:hover {
    background-color: #0056b3;
  }

  &:first-child {
    background-color: #6c757d;
    &:hover {
      background-color: #5a6268;
    }
  }
`;


const CheckboxInput = styled.input.attrs({ type: 'checkbox' })`
  margin-top: 5px;
`;

const TextareaInput = styled.textarea`
  padding: 8px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
`;


function EditUserModal({ isOpen, onClose, userData, onSave }) {
  const [user, setUser] = useState({});
  const [originalUser, setOriginalUser] = useState({});
  const [modules, setModules] = useState([]);
  const [modulesAssigned, setModulesAssigned] = useState({});
  const { error, execute } = useFetchWithMsal({
    scopes: protectedResources.api.scopes.read,
  });

  useEffect(() => {
    if (isOpen) {
      setUser(userData);
      setOriginalUser(userData);

      // Fetch modules from the backend
      const fetchModules = async () => {
        try {

          // Fetch modules from the backend
          execute('GET', `${protectedResources.api.baseEndPoint}/modules`).then((response) => {
            console.log(response);
            setModules(response.data);
            // Initialize modulesAssigned state
            const initialAssignedState = response.data.reduce((acc, module) => {
              console.log(acc, module);
              acc[module.ShortName] = false;
              return acc;
            }, {});
            console.log(initialAssignedState);
            setModulesAssigned(initialAssignedState);
            console.log(userData);
            if (userData.Modules) {
              const modulesArray = userData.Modules.split(',');
              const modulesObject = modulesArray.reduce((acc, moduleName) => {
                console.log(acc, moduleName);
                acc[moduleName.trim()] = true;
                return acc;
              }, {});
              setModulesAssigned((prev) => ({ ...prev, ...modulesObject }));
            }
          });

        } catch (error) {
          console.error('Error fetching modules:', error);
        }
      };

      fetchModules();
    }
  }, [execute, userData, isOpen]);

  const handleChange = (e) => {
    const { name, type, checked, value } = e.target;
    if(type !== 'checkbox') {
      setUser((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
    setModulesAssigned((prev) => ({
      ...prev,
      [name]: checked,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const roleValue = user.Role;
    const activeModules = Object.keys(modulesAssigned).reduce((acc, key) => {
      if (modulesAssigned[key]) {
        acc[key] = true;
      }
      return acc;
    }, {});

    const modifiedFields = Object.keys(user).filter(
      (key) => user[key] !== originalUser[key]
    );
    console.log("before edit organization submit", user, originalUser);
    try {
      execute('PUT', `${protectedResources.api.baseEndPoint}/organizations`, {
        ...user,
        UserId: originalUser.UserId,
        Role: roleValue,
        modifiedFields,
        activeModules,
      }).then((response) => {
        if (response.success) {
          onSave(user);
          onClose();
          alert('User updated successfully!');
        } else {
          alert(response.message || 'Failed to update user');
        }
      });
    } catch (error) {
      console.error('Failed to update user:', error);
      alert('Error updating user: ' + error.message);
    }
  };

  if (!isOpen) return null;

  return (
    <ModalOverlay>
      <ModalContainer>
        <ModalHeader>Edit User</ModalHeader>
        <ModalForm onSubmit={handleSubmit}>
          <FormLabel htmlFor="Org_Email">Email</FormLabel>
          <FormInput
            type="email"
            id="Org_Email"
            name="Org_Email"
            disabled="true"
            value={user.Org_Email || ''}
            onChange={handleChange}
          />

          <FormLabel htmlFor="Org_Name">Organization Name</FormLabel>
          <FormInput
            id="Org_Name"
            name="Org_Name"
            value={user.Org_Name || ''}
            onChange={handleChange}
          />

          <FormLabel htmlFor="Org_Address">Organization Address</FormLabel>
          <FormInput
            id="Org_Address"
            name="Org_Address"
            value={user.Org_Address || ''}
            onChange={handleChange}
          />

          <FormLabel>Modules to Assign</FormLabel>
          {modules.map((module) => (
            <div key={module.ShortName}>
              <CheckboxInput
                id={module.ShortName}
                name={module.ShortName}
                checked={modulesAssigned[module.ShortName] || false}
                onChange={handleChange}
              />
              <label htmlFor={module.ShortName}>{module.ModuleName}</label>
            </div>
          ))}

          <ButtonsRow>
            <Button type="submit">Save Changes</Button>
            <Button type="button" onClick={onClose}>
              Cancel
            </Button>
          </ButtonsRow>
        </ModalForm>
      </ModalContainer>
    </ModalOverlay>
  );
}

export default EditUserModal;
