import styled from "styled-components";
import useFetchWithMsal from 'hooks/useFetchWithMsal';
import { protectedResources } from 'config/msalConfig';
import React, { useState, useEffect } from 'react';


const NewOrgModal = ({ isOpen, onClose, onAddOrganization }) => {
    const [organizationName, setOrganizationName] = useState('');
    const [address, setAddress] = useState('');
    const [adminEmail, setAdminEmail] = useState('');
    const [emailError, setEmailError] = useState(null);
    const [modules, setModules] = useState([]);
    const [modulesAssigned, setModulesAssigned] = useState({});
    const [isSendingRequest, setIsSendingRequest] = useState(false);
    const { error, execute } = useFetchWithMsal({
      scopes: protectedResources.api.scopes.read,
    });
  
    useEffect(() => {
      // Fetch modules from the backend when the component mounts
      const fetchModules = async () => {
        try {
  
          execute("GET", `${protectedResources.api.baseEndPoint}/modules`).then((response) => {
            if (response && response.success) {
              console.log('response', response);
              setModules(response.data); // Adjust the response according to your API
              // Initialize modulesAssigned state
              const initialAssignedState = response.data.reduce((acc, module) => {
                acc[module.ShortName] = false;
                return acc;
              }, {});
              setModulesAssigned(initialAssignedState);
            }
          });
  
  
        } catch (error) {
          console.error('Error fetching modules:', error);
        }
      };
  
      fetchModules();
    }, [execute]);
  
    if (!isOpen) return null;
  
    const checkIfUserWithEmailExists = async (email) => {
      try {
        const response = await execute('GET', `${protectedResources.api.baseEndPoint}/customerUsers/exists/${email}`);
        if (response.success) {
          return response.data;
        } else {
          alert(response.message || 'Failed to check if user exists');
          return { exists: false };
        }
      } catch (error) {
        console.error('Error checking if user exists:', error);
        alert('Error checking if user exists: ' + error.message);
        return { exists: false };
      }
    };
  
    const ValidateEmail = async (email) => {
      console.log('custEmail:', email);
      if (!email) {
        setEmailError('Email is required');
      }
      //  check if email is valid and suffix with domain
      else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
        setEmailError('Invalid email format');
      } else {
        setEmailError('Checking if user exists...');
        var emailExists = await checkIfUserWithEmailExists(email);
        if (emailExists.exists) {
          setEmailError('A user with this email already exists');
        } else {
          setEmailError(null);
          return true;
        }
      }
      return false;
    };
  
    const onEmailChange = async (email) => {
      setAdminEmail(email);
      await ValidateEmail(email);
    };
  
    function clearForm() {
      setOrganizationName(''); // Clear the form fields
      setAddress('');
      setAdminEmail('');
      setModulesAssigned({});
    }
  
    const handleSubmit = async (event) => {
      event.preventDefault(); // Prevent the default form submission behavior
      console.log({ organizationName, address, adminEmail, modulesAssigned });
      try {
        setIsSendingRequest(true);
        execute("POST", `${protectedResources.api.baseEndPoint}/organizations`, {
          organizationName,
          address,
          adminEmail,
          modulesAssigned,
        }).then((response) => {
          setIsSendingRequest(false);
          if (response && response.success) {
            console.log('response', response);
            onAddOrganization(); // Call onAddOrganization to re-fetch records
            clearForm();
  
            onClose(); // Close the modal on success
          } else {
            throw new Error(response.message || 'Failed to create organization');
          }
  
  
        });
      } catch (error) {
        console.error('Error:', error);
        setIsSendingRequest(false);
      }
    };
  
    const handleModuleChange = (ModuleName, event) => {
      setModulesAssigned((prevState) => ({
        ...prevState,
        [ModuleName]: event.target.checked,
      }));
    };
  
    return (
      <>
        <Overlay onClick={onClose} >
        <Modal>
          <h2>New Organization</h2>
          <ModalForm onSubmit={handleSubmit}>
            <FormLabel>
              Organization Name
              <Input
                type="text"
                value={organizationName}
                onChange={(e) => setOrganizationName(e.target.value)}
                placeholder="Type in"
              />
            </FormLabel>
            <FormLabel>
              Address
              <Input
                type="text"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                placeholder="Type in"
              />
            </FormLabel>
            <FormLabel>
              Admin User Email
              <Input
                className={adminEmail && (emailError ? 'error' : 'success')}
                type="email"
                value={adminEmail}
                onChange={async e => await onEmailChange(e.target.value)}
                placeholder="Type in"
              />
            </FormLabel>
            {emailError && <EmailError>{emailError}</EmailError>}
            <FormLabel>
              Modules To Assign
              <CheckboxGroup>
                {modules.map((module) => (
                  <div key={module.ShortName}>
                    <input
                      type="checkbox"
                      id={module.ShortName}
                      name={module.ShortName}
                      checked={modulesAssigned[module.ShortName] || false}
                      onChange={(e) => handleModuleChange(module.ShortName, e)}
                    />
                    <label htmlFor={module.ShortName}>{module.ModuleName}</label>
                  </div>
                ))}
              </CheckboxGroup>
            </FormLabel>
            <ButtonsRow>
              <Button type="submit" disabled={isSendingRequest}>Submit</Button>
              <Button type="button" disabled={isSendingRequest} onClick={onClose}>
                Close
              </Button>
            </ButtonsRow>
          </ModalForm>
        </Modal>
        </Overlay>
      </>
    );
  };


  
const Modal = styled.div`
position: fixed;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
background: white;
padding: 30px;
z-index: 2;
width: 80%;
max-width: 600px;
border: 1px solid #ccc;
margin-bottom: 100px;
border-radius: 8px;
box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
height: 660px;
overflow-y: auto;
`;

const Overlay = styled.div`
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
background: rgba(0, 0, 0, 0.5);
z-index: 1;
`;


const ModalForm = styled.form`
display: flex;
flex-direction: column;
gap: 20px; // Spacing between form fields
`;


const FormLabel = styled.label`
display: block;  // Ensures the label is a block element
margin-bottom: 8px;  // Increased space between the label text and the input field
color: #333;  // Optional: text color for the label
`;

const Input = styled.input`
padding: 8px 10px;
font-size: 16px;
border: 1px solid #ccc;
border-radius: 4px;
width: 100%; // Ensures input fields expand to full container width
`;
const EmailError = styled.span`
  color: red;
`;
const Button = styled.button`
padding: 10px 20px;
font-size: 16px;
background-color: #f60; // Orange background
color: white;
border: none;
border-radius: 4px;
cursor: pointer;
transition: background-color 0.2s;
&:hover {
  background-color: #e55; // Darker orange on hover
}
`;

const ButtonsRow = styled.div`
display: flex;
justify-content: center;  // Centers the buttons horizontally
gap: 10px;                // Spacing between buttons
`;

const CheckboxGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 5px;

  & > div {
      margin-bottom: 10px;
  }

  input[type="checkbox"] {
      margin-right: 10px;
  }


  label {
      cursor: pointer;
  }
  `;


  export default NewOrgModal;