import React, { useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { FaFilePen, FaTrash, FaUsers, FaEnvelope } from 'react-icons/fa6';
import useFetchWithMsal from 'hooks/useFetchWithMsal';
import { protectedResources } from 'config/msalConfig';
import EditUserModal from './EditUserModal';
import DeleteConfirmationModal from 'components/shared/DeleteConfirmationModal';


function formatDate(dateString) {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US');  // Adjust 'en-US' as needed for your locale
}

function OrgData({ searchResults, records, setRecords, fetchRecords }) {
    console.log("Data", records, records?.length);
  
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [currentRecord, setCurrentRecord] = useState(null);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const { execute } = useFetchWithMsal({
      scopes: protectedResources.api.scopes.read,
    });
  
    const navigate = useNavigate();
  
    const handleRowClick = (organizationName) => {
      navigate(`/org-customers/${organizationName}`);
    };
  
    const handleSendInvitation = async (record) => {
      console.log('Send Invitation:', record);
      try {
        var response = await execute('POST', `${protectedResources.api.baseEndPoint}/organizations/sendSignupInvitationOrgUser`, {
          Org_ID: record.Org_ID
        });
        if (response.success) {
          alert('Invitation sent successfully');
        } else {
          throw new Error(response.message || 'Failed to send invitation');
        }
      } catch (error) {
        alert('Failed to send invitation: ' + error.message);
      }
    };
  
    const handleEdit = (record) => {
      setCurrentRecord({ ...record });
      setIsEditModalOpen(true);
    };
  
    const handleCloseModal = () => {
      setIsEditModalOpen(false);
    };
  
    const handleSaveEditedUser = (updatedRecord) => {
      fetchRecords();
      handleCloseModal();
    };
  
    const handleDelete = (userId, record) => {
      setCurrentRecord({ ...record, Org_ID: userId });
      setDeleteModalOpen(true);
    };
  
    const handleConfirmDelete = async () => {
      try {
        execute("DELETE", `${protectedResources.api.baseEndPoint}/organizations`, {
          orgId: currentRecord.Org_ID
        }).then((response) => {
          if (response && response.success) {
            console.log('response', response);
            const updatedRecords = records.filter(record => record.Org_ID !== currentRecord.Org_ID);
            setRecords(updatedRecords);
            setDeleteModalOpen(false);
          } else {
            throw new Error(response.message || 'Failed to delete organization');
          }
        });
      } catch (error) {
        alert('Failed to delete user: ' + error.message);
      }
    };
  
    return (
      <DataContainer>
        <DataTable>
          <thead>
            <TableRow>
              <TableHeader>Created On</TableHeader>
              <TableHeader>Organization Name</TableHeader>
              <TableHeader>Email</TableHeader>
              <TableHeader>Org Address</TableHeader>
              <TableHeader>Modules</TableHeader>
              <TableHeader>Actions</TableHeader>
            </TableRow>
          </thead>
          <tbody>
            {records?.length > 0 ? (
              records.map((record, index) => (
                <TableRow key={index} >
                  <TableData>{formatDate(record.CreatedOn)}</TableData>
                  <TableData>{record.Org_Name}</TableData>
                  <TableData>{record.Org_Email}</TableData>
                  <TableData>{record.Org_Address}</TableData>
                  <TableData>{record.Modules}</TableData>
                  <TableData>
                    <ActionButton title="Resend the invitation" onClick={(e) => {
                      e.stopPropagation();
                      handleSendInvitation(record);
                    }}>
                      <FaEnvelope style={{ color: '#ffad1c' }} />
                    </ActionButton>
                    <ActionButton onClick={(e) => {
                      e.stopPropagation();
                      handleEdit(record);
                    }}>
                      <FaFilePen style={{ color: '#ffad1c' }} />
                    </ActionButton>
                    <ActionButton onClick={(e) => {
                      e.stopPropagation();
                      handleDelete(record.Org_ID, record);
                    }}>
                      <FaTrash style={{ color: '#ffad1c' }} />
                    </ActionButton>
                    <ActionButton onClick={(e) => {
                      e.stopPropagation();
                      navigate(`/org-customers/${record.Org_ID}`);
                    }}>
                      <FaUsers style={{ color: '#ffad1c' }} />
                    </ActionButton>
                  </TableData>
                </TableRow>
              ))
            ) : (
              <TableRow>
                {/* <TableData colSpan="5">No data available</TableData> */}
              </TableRow>
            )}
          </tbody>
        </DataTable>
        {isEditModalOpen && <EditUserModal isOpen={isEditModalOpen} onClose={handleCloseModal} userData={currentRecord} onSave={handleSaveEditedUser} />}
        {isDeleteModalOpen && <DeleteConfirmationModal isOpen={isDeleteModalOpen} onClose={() => setDeleteModalOpen(false)} record={currentRecord} onDeleteConfirmed={handleConfirmDelete} />}
      </DataContainer>
    );
  }

  
const Icon = styled.img`
width: 20px; // Adjust width as needed
height: 20px; // Adjust height as needed
margin-right: 5px; // Space between icons
cursor: pointer;
`;
const ActionButton = styled.button`
background: none;
border: none;
cursor: pointer;
padding: 5px;
margin: 0 2px; // Adds spacing between the buttons
display: inline-flex; // Ensures the icons align properly
align-items: center; // Centers the icons vertically
`;
const ActionSeparator = styled.span`
margin: 0 10px;
color: #ccc; // Style for the separator
`;




const DataContainer = styled.div`
  height: 450px; /* Set your desired height */
  overflow-y: auto; /* Add vertical scrollbar when content overflows */
  margin-left: -155px;
  width:125%;
`;

const DataTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
      background-color: #f2f2f2;
  }
`;
const TableHeader = styled.th`
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
`;

const TableData = styled.td`
  padding: 20px 15px;
  border-bottom: 1px solid #ddd;
`;

export default OrgData;