
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useUser } from 'contexts/UserContext';
import EditAdmin from 'views/UserManager/EditAdmin';
import useFetchWithMsal from 'hooks/useFetchWithMsal';
import { protectedResources } from 'config/msalConfig';
import { FaTrash } from 'react-icons/fa6';



function UserData({ searchResults }) {
    const { userDetails } = useUser();
    const [records, setRecords] = useState(searchResults || []);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [currentRecord, setCurrentRecord] = useState(null);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const { execute } = useFetchWithMsal({
      scopes: protectedResources.api.scopes.read,
    });
    useEffect(() => {
      const fetchInitialRecords = async () => {
        try {
          const response = await execute("GET", `${protectedResources.api.baseEndPoint}/adminUsers`);
          if (response) {
            if (response.success) {
              setRecords(response.data);
              setLoading(false);
            }
          }
        } catch (error) {
          setError(error);
          setLoading(false);
        }
      };
  
      fetchInitialRecords();
    }, [execute]);
  
    useEffect(() => {
      if (searchResults) {
        setRecords(searchResults);
      }
    }, [searchResults]);
  
    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error.message || 'An error occurred'}</div>;
  
    const recordsToShow = userDetails ?
      records.filter(record => record.email !== userDetails.email) :
      records;
  
    const handleEdit = (userId, record) => {
      setCurrentRecord({ ...record, UserId: userId });
      setIsEditModalOpen(true);
    };
  
    const handleDelete = (record) => {
      setCurrentRecord({ ...record });
      setDeleteModalOpen(true);
    };
  
    const handleCloseModal = () => {
      setIsEditModalOpen(false);
    };
  
    const handleSaveEditedUser = updatedRecord => {
      const updatedRecords = records.map(rec => rec.UserID === updatedRecord.userID ? updatedRecord : rec);
      setRecords(updatedRecords);
      handleCloseModal();
    };
  
    const handleConfirmDelete = async () => {
      const newStatus = !currentRecord.isActive;
      const updatedRecords = records.map(record =>
        record.email === currentRecord.email ? { ...record, isActive: newStatus } : record
      );
      setRecords(updatedRecords);
    };
  
    return (
      <DataContainer>
        <DataTable>
          <thead>
            <TableRow>
              <TableHeader>User Email</TableHeader>
              <TableHeader>Status</TableHeader>
              <TableHeader>Action</TableHeader>
            </TableRow>
          </thead>
          <tbody>
            {recordsToShow.length > 0 ? (
              recordsToShow.map((record, index) => {
  
                return (
                  <TableRow key={index}>
                    <TableData>{record.email}</TableData>
                    <TableData>
                      <StatusButton $active={record.isActive === true}>
                        {record.isActive === true ? 'Active' : 'Inactive'}
                      </StatusButton>
                    </TableData>
                    <TableData>
                      <ActionButton
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent row click
                          handleDelete(record);
                        }}
                      >
                        <FaTrash style={{ color: '#ffad1c' }} />
                      </ActionButton>
                    </TableData>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableData colSpan="8">No data available</TableData>
              </TableRow>
            )}
          </tbody>
        </DataTable>
        {isDeleteModalOpen && <EditAdmin isOpen={isDeleteModalOpen} onClose={() => setDeleteModalOpen(false)} record={currentRecord} onDeleteConfirmed={handleConfirmDelete} />}
      </DataContainer>
    );
  }

  
const StatusButton = styled.span`
display: inline-block;
width: 80px;  // Set a fixed width
height: 25px; // Set a fixed height
line-height: 25px; // Use line height equal to height for vertical centering
color: white;
background-color: ${props => props.$active ? '#4CAF50' : '#808080'};  // Grey color for inactive
padding: 0;
text-align: center; // Center text horizontally
border-radius: 5px;
cursor: default;
`;

const Icon = styled.img`
width: 20px; // Adjust width as needed
height: 20px; // Adjust height as needed
margin-right: 5px; // Space between icons
cursor: pointer;
`;

const ActionButton = styled.button`
background: none;
border: none;
cursor: pointer;
padding: 5px;
margin: 0 2px; // Adds spacing between the buttons
display: inline-flex; // Ensures the icons align properly
align-items: center; // Centers the icons vertically
`;

const ActionSeparator = styled.span`
margin: 0 10px;
color: #ccc; // Style for the separator
`;

const DataContainer = styled.div`
height: 400px; /* Set your desired height */
overflow-y: auto; /* Add vertical scrollbar when content overflows */
width:130%;
margin-left: -150px;
`;

const DataTable = styled.table`
width: 100%;
border-collapse: collapse;
`;

const TableRow = styled.tr`
&:nth-child(even) {
    background-color: #f2f2f2;
}
cursor: pointer; /* Changes cursor to indicate clickability */
`;

const TableHeader = styled.th`
padding: 8px;
text-align: left;
border-bottom: 1px solid #ddd;
background: white;  // Ensures the header isn't transparent
position: sticky;
top: 0;  // Adjust this if your table is not at the very top of the page
z-index: 1;  // Ensures the header is above other content when scrolling
`;


const TableData = styled.td`
padding: 20px 15px;
border-bottom: 1px solid #ddd;
`;

export default UserData;