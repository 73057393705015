import { App, Button, Checkbox, DatePicker, Form, Input, InputNumber, Modal, Space, Switch } from "antd";
import { CloseSquareFilled, MinusCircleOutlined, PlusOutlined, SaveFilled } from '@ant-design/icons';
import { useEffect } from "react";
import useFetchWithMsal from 'hooks/useFetchWithMsal';
import { protectedResources } from "config/msalConfig";
import { get } from "lodash";

export const DataEditor = ({ isOpen, dataItem, onDataEditorFinished, onDataEditorCancelled }) => {
    const [form] = Form.useForm();
    const { message } = App.useApp();
    const { execute } = useFetchWithMsal({
        scopes: protectedResources.api.scopes.read,
    });

    const handleDataEditFinished = async (values) => {
        console.log(values)
        console.log("handleDataEditFinished");
        var newData = { ...dataItem, ...values };
        console.log(newData);
        // return
        if (newData.Id) {
            // call api to update data
            const response = await execute('PUT', `${protectedResources.api.baseEndPoint}/customerRolesWithValue`, newData);
            if (response.success) {
                message.success("Customer updated successfully");
                newData = { ...newData, ...response.data };
            } else {
                message.error(response.message);
                return;
            }
        }
        else {
            // call  api to add data
            const response = await execute('POST', `${protectedResources.api.baseEndPoint}/customerRolesWithValue`, newData);
            if (response.success) {
                message.success("Customer added successfully");
                newData = { ...newData, ...response.data };
            } else {
                message.error(response.message);
                return;
            }
        }
        onDataEditorFinished(newData);
    }

    const handleDataEditCancelled = () => {
        console.log("handleDataEditCancelled");
        onDataEditorCancelled();
    }

    useEffect(() => {
        if (isOpen) {
            // console.log(dataItem)
            // const roleValues = dataItem.RoleValues.map(role => role.RoleValue);
            // console.log(roleValues)
            form.setFieldsValue({
                role_name: dataItem.Name,
                role_values: dataItem.RoleValues
            });
        }
    }, [isOpen, dataItem, form]);

    const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 4,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 20,
    },
  },
};
const formItemLayoutWithOutLabel = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 20,
      offset: 4,
    },
  },
};

    return (
        <Modal
            open={isOpen}
            title={dataItem.Id ? "Edit Customer Role" : "Add Customer Role"}
            okText={<Space><SaveFilled />Save</Space>}
            cancelText={<Space><CloseSquareFilled />Cancel</Space>}
            okButtonProps={{ autoFocus: true, htmlType: 'submit' }} //form: 'dataEditorForm', key: 'submit'
            onCancel={handleDataEditCancelled}
            destroyOnClose
            modalRender={(dom) => (
                <Form
                    layout="horizontal"
                    form={form}
                    name="dataEditorForm"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    initialValues={{
                        modifier: 'public',
                    }}
                    clearOnDestroy
                    onFinish={handleDataEditFinished}
                >
                    {dom}
                </Form>
            )}>
            <Form.Item
                name="Customer_ID"
                label="Customer ID"
                hidden>
                <Input />
            </Form.Item>


            <Form.Item
                name="role_name"
                label="Role Name"
                rules={[{ required: true, message: 'Role Name is required' }]}>
                <Input />
            </Form.Item>
            <Form.List
                name="role_values"
                // rules={[
                //     {
                //         validator: async (_, names) => {
                //             if (!names || names.length < 2) {
                //                 return Promise.reject(new Error('At least 2 passengers'));
                //             }
                //         },
                //     },
                // ]}
            >
                {(fields, { add, remove }, { errors }) => (
                    <>
                        {fields.map((field, index) => (
                            <Form.Item
                                {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                                label={index === 0 ? 'Role Values' : ''}
                                required={false}
                                key={field.key}
                            >
                                
                                <Input.Group compact>
                                    {/* Input for RoleValueId */}
                                    <Form.Item
                                        className="w-0px h-0px"
                                        name={[field.name, 'RoleValueId']}
                                        // rules={[
                                        //     {
                                        //         required: true,
                                        //         message: "Please input Role Value ID!",
                                        //     },
                                        // ]}
                                        style={{
                                            width: '30%',
                                            marginRight: '10px',
                                        }}
                                    >
                                        <Input
                                        className="w-0"
                                            type="hidden"
                                            placeholder="Role Value ID"
                                        />
                                    </Form.Item>

                                    {/* Input for RoleValue */}
                                    <Form.Item
                                        name={[field.name, 'RoleValue']}
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please input Role Value!",
                                            },
                                        ]}
                                        style={{
                                            width: '60%',
                                        }}
                                    >
                                        <Input
                                            placeholder="Role Value"
                                        />
                                    </Form.Item>

                                    {/* Delete Button */}
                                    <MinusCircleOutlined
                                        className="dynamic-delete-button"
                                        onClick={() => remove(field.name)}
                                    />
                                </Input.Group>

                                {/* <Form.Item
                                    {...field}
                                    validateTrigger={['onChange', 'onBlur']}
                                    rules={[
                                        {
                                            required: true,
                                            whitespace: true,
                                            message: "Please input Role Values or delete this field.",
                                        },
                                    ]}
                                    noStyle
                                >
                                    <Input
                                        placeholder="Role Value"
                                        style={{
                                            width: '60%',
                                        }}
                                    />
                                </Form.Item>
                                {fields.length > 1 ? (
                                    <MinusCircleOutlined
                                        className="dynamic-delete-button"
                                        onClick={() => remove(field.name)}
                                    />
                                ) : null} */}
                            </Form.Item>
                        ))}
                        <Form.Item>
                            <Button
                                type="dashed"
                                onClick={() => add()}
                                style={{
                                    width: '60%',
                                }}
                                icon={<PlusOutlined />}
                            >
                                Add Role Value
                            </Button>
                            {/* <Button
                                type="dashed"
                                onClick={() => {
                                    add('The head item', 0);
                                }}
                                style={{
                                    width: '60%',
                                    marginTop: '20px',
                                }}
                                icon={<PlusOutlined />}
                            >
                                Add field at head
                            </Button> */}
                            <Form.ErrorList errors={errors} />
                        </Form.Item>
                    </>
                )}
            </Form.List>
            <Form.Item
                name="status"
                label="Status"
            >
                <Switch defaultChecked />
            </Form.Item>

            <Form.Item
                name="all_modules"
                label="All Modules"
                valuePropName="checked"
            >
                <Checkbox></Checkbox>
            </Form.Item>
           
        </Modal>
    );
};