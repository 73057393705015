import React, { useState } from 'react';
import styled from 'styled-components';
import useFetchWithMsal from 'hooks/useFetchWithMsal';
import { protectedResources } from 'config/msalConfig';


function NewUserModal({ isOpen, onClose, fetchRecords }) {
    const [adminEmail, setAdminEmail] = useState('');
    const [emailError, setEmailError] = useState(null);
    const { execute } = useFetchWithMsal({
      scopes: protectedResources.api.scopes.read,
    });
  
    const onAdminEmailChange = async (email) => {
      setAdminEmail(email);
      await ValidateEmailDomain(email);
    };
  
    const checkIfUserWithEmailExists = async (email) => {
      try {
        const response = await execute('GET',`${protectedResources.api.baseEndPoint}/adminUsers/exists/${email}`);
        if(response.success){        
          return response.data;
        }else{
          alert(response.message || 'Failed to check if user exists');
          return { exists: false };
        }
      } catch (error) {
        console.error('Error checking if user exists:', error);
        alert('Error checking if user exists: ' + error.message);
        return { exists: false };
      }
    };
  
    const ValidateEmailDomain = async (email) => {
      console.log('custEmail:', email);
      if (!email) {
        setEmailError('Email is required');
      }    
      //  check if email is valid and suffix with domain
      else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
        setEmailError('Invalid email format');
      } else{ 
        setEmailError('Checking if user exists...');
        var emailExists = await checkIfUserWithEmailExists(email);
        if(emailExists.exists){
          setEmailError('A user with this email already exists');
        }else {
          setEmailError(null);
          return true;
        }
      }
      return false;
    };
  
    const clearForm = () => {
      setAdminEmail('');
    };
  
    const handleSubmit = async (event) => {
      event.preventDefault();
      if (!await ValidateEmailDomain(adminEmail)) {
        alert('either email is invalid or already exists');
        return;
      }
      try {
        const response = await execute('POST', `${protectedResources.api.baseEndPoint}/adminUsers`, { adminEmail });
        if (response.success) {
          console.log('Success:', response.data);
          alert(`Success: ${response.message}`);
          await fetchRecords(); // Refresh the list of admin users
          clearForm(); // Clear the form fields
          onClose(); // Close the modal on success
        } else {
          throw new Error(response.message || 'Failed to create admin user');
        }
      } catch (error) {
        console.error('Error:', error);
        alert(`Error: ${error.message}`);
      }
    };
  
    if (!isOpen) return null;
  
    return (
      <>
        <Overlay onClick={onClose} />
        <Modal>
          <h2>New Admin User</h2>
          <ModalForm onSubmit={handleSubmit}>
            <FormLabel>
              User Email
            </FormLabel>
              <Input
                type="email"
                className={adminEmail && (emailError ? 'error' : 'success')}
                value={adminEmail}
                onChange={async e => await onAdminEmailChange(e.target.value)}
                placeholder="Type in"
              />{emailError && <EmailError>{emailError}</EmailError>} 
            <ButtonsRow>
              <Button type="submit">Submit</Button>
              <Button type="button" onClick={onClose}>Close</Button>
            </ButtonsRow>
          </ModalForm>
        </Modal>
      </>
    );
  }



  const Modal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 30px;
  z-index: 2;
  width: 80%;
  max-width: 600px;
  border: 1px solid #ccc;
  margin-bottom: 100px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
`;

const ModalForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px; // Spacing between form fields
  .error {
    border: 1px solid #f00;
  }
  .success {
    border: 1px solid #0f0;
  }
`;

const FormLabel = styled.label`
  display: block;  // Ensures the label is a block element
  margin-bottom: 8px;  // Increased space between the label text and the input field
  color: #333;  // Optional: text color for the label
  .error {
    border: 1px solid #f00;
  }
`;

const Input = styled.input`
  padding: 8px 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%; // Ensures input fields expand to full container width
`;
const EmailError = styled.span`
  color: red;
`;
const Button = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  background-color: #f60; // Orange background
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
  &:hover {
    background-color: #e55; // Darker orange on hover
  }
`;

const CheckboxGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 5px;

  & > div {
    margin-bottom: 10px;
  }

  input[type="checkbox"] {
    margin-right: 10px;
  }

  label {
    cursor: pointer;
  }
`;

const ButtonsRow = styled.div`
  display: flex;
  justify-content: center;  // Centers the buttons horizontally
  gap: 10px;                // Spacing between buttons
`;

  export default NewUserModal;