import styled from "styled-components";
import { FaFilePen, FaTrash, FaEnvelope, FaPlus, FaUsers } from 'react-icons/fa6';
import { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useFetchWithMsal from 'hooks/useFetchWithMsal';
import { protectedResources } from 'config/msalConfig';
import DeleteConfirmationModal from "components/shared/DeleteConfirmationModal";
import { Badge, Button, Space, Table, Input } from "antd";
import qs from 'qs';
// import { DataEditor } from "./DataEditor";
// import OrgModuleData from "components/shared/OrganizationModules/OrgModuleData";
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import EditUserModal from './EditUserModal';
import { App, message } from 'antd';
import NewOrganizationModal from './NewOrgModal';
// import RoleValueTable from "./RoleValueSubTable/RoleValueTable";

// import { NewOrganizationModal } from "./NewOrganizationModal";



function formatDate(dateString) {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US');  // Adjust 'en-US' as needed for your locale
}

function OrgManagementTable({ userDetails, searchResults }) {    
    const [isDataEditorOpen, setIsDataEditorOpen] = useState(false);        
    const [editableDataItem, setEditableDataItem] = useState({});

    const navigate = useNavigate();


  const [isEditModalOpen, setIsEditModalOpen] = useState(false);


    const [modalIsOpen, setModalIsOpen] = useState(false);

    const toggleModal = () => {
        setIsDataEditorOpen(!isDataEditorOpen);
        setModalIsOpen(!modalIsOpen)
    };

    // const handleUserAdded = () => {
    //     setRefreshData(!refreshData); // Toggle refreshData to trigger data refetch
    //   };

    console.log("userDetails/////////////")
    console.log(userDetails)

    const [currentRecord, setCurrentRecord] = useState(null);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const { orgId } = useParams();
    const [customers, setCustomers] = useState(searchResults || []);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const { execute } = useFetchWithMsal({
      scopes: protectedResources.api.scopes.read,
    });
    const [tableParams, setTableParams] = useState({
        pagination: {
          current: 1,
          pageSize: 4,
        },
    });

    const openEditorToAdd = () => {
        console.log("openEditorToAdd");
        setEditableDataItem({Org_ID: orgId});
        setModalIsOpen(true);
    };

    const openEditorToEdit = (record) => {
        console.log("edit", record);
        setEditableDataItem(record);
        setIsDataEditorOpen(true);
    };

    //searchFilter
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
        <div
            style={{
            padding: 8,
            }}
            onKeyDown={(e) => e.stopPropagation()}
        >
            <Input
            ref={searchInput}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{
                marginBottom: 8,
                display: 'block',
            }}
            />
            <Space>
            <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                icon={<SearchOutlined />}
                size="small"
                style={{
                width: 90,
                }}
            >
                Search
            </Button>
            <Button
                onClick={() => clearFilters && handleReset(clearFilters)}
                size="small"
                style={{
                width: 90,
                }}
            >
                Reset
            </Button>
            <Button
                type="link"
                size="small"
                onClick={() => {
                confirm({
                    closeDropdown: false,
                });
                setSearchText(selectedKeys[0]);
                setSearchedColumn(dataIndex);
                }}
            >
                Filter
            </Button>
            <Button
                type="link"
                size="small"
                onClick={() => {
                close();
                }}
            >
                close
            </Button>
            </Space>
        </div>
        ),
        filterIcon: (filtered) => (
        <SearchOutlined
            style={{
            color: filtered ? '#1677ff' : undefined,
            }}
        />
        ),
        onFilter: (value, record) =>
        record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
        filterDropdownProps: {
        onOpenChange(open) {
            if (open) {
            setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        },
        render: (text) =>
        searchedColumn === dataIndex ? (
            <Highlighter
            highlightStyle={{
                backgroundColor: '#ffc069',
                padding: 0,
            }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
            />
        ) : (
            text
        ),
    });

  //endSearchFilter

    const columns = [
        {
            title: 'Created On',
            dataIndex: 'CreatedOn',
            key: 'CreatedOn',
            sorter: true,
            render: (text) => formatDate(text),
        },
        {
            title: 'Organization Name',
            dataIndex: 'Org_Name',
            key: 'Org_Name',
            sorter: true,
            ...getColumnSearchProps('Org_Name'),
        },
        {
            title: 'Email',
            dataIndex: 'Org_Email',
            key: 'Org_Email',
            sorter: true,
            ...getColumnSearchProps('Org_Email'),
        },
        {
            title: 'Org Address',
            dataIndex: 'Org_Address',
            key: 'Org_Address',
            sorter: true,
            ...getColumnSearchProps('Org_Address'),

        },
        {
            title: 'Modules',
            dataIndex: 'Modules',
            key: 'Modules',
            sorter: true,
            ...getColumnSearchProps('Modules'),
           
        },
        {
            title: <Button color="default" variant="text" onClick={ openEditorToAdd } icon={<FaPlus  style={{ color: '#ffad1c' }} />} />,
            key: 'actions',
            render: (text, record) => (
                <>
                    <Button  color="default" variant="text"  title="Resend the invitation" onClick={(e) => {
                        e.stopPropagation();
                        handleSendInvitation( record, record.UserID );
                    }} icon={<FaEnvelope style={{ color: '#ffad1c' }} />} />
                                            
                    <Button   color="default" variant="text" onClick={(e) => {
                        console.log(record)
                        e.stopPropagation(); // Prevent row click
                        // openEditorToEdit(record);
                        setCurrentRecord({ ...record, UserId: record.UserID });
                        setIsEditModalOpen(true);
                    }} icon={<FaFilePen  style={{ color: '#ffad1c' }} />} />
                                        
                    <Button   color="default" variant="text" onClick={(e) => {
                        e.stopPropagation(); // Prevent row click
                        handleDelete(record.UserID,record);
                    }} icon={<FaTrash style={{ color: '#ffad1c' }} />} />

                    <Button color="default" variant="text" onClick={(e) => {
                      e.stopPropagation();
                      navigate(`/org-customers/${record.Org_ID}`);
                    }}icon={<FaUsers style={{ color: '#ffad1c' }} />} />
                </>
                
            ),
        },
    ];

    const handleSaveEditedUser = async updatedRecord => {
        await fetchData(); // Refetch data after deletion
        setIsEditModalOpen(false);
      };

    const handleDataEditFinished = (newData) => {
        console.log("handleDataEditFinished", newData, editableDataItem);
        setIsDataEditorOpen(false);
        fetchData();
    };

    const handleDataEditCancelled = () => {
        console.log("handleDataEditCancelled");
        setIsDataEditorOpen(false);
    };


    const getTableParams = (params) => ({
        pS: params.pagination?.pageSize,
        pN: params.pagination?.current,
        sF: params.sortField,
        sO: params.sortOrder,
        f: params.filters
        // ...params,
    });
  const { message, model, notification } = App.useApp();


    const handleSendInvitation = async (record, userId) => {
        console.log('Send Invitation:', userId, record);
        try {
        var response = await execute('POST', `${protectedResources.api.baseEndPoint}/customerUsers/sendSignupInvitation`, {
          userId: userId
        });
        if(response.success){
          message.success('Invitation sent successfully');
        }else{
          message.error(response.message || 'Failed to send invitation');
        }
        }catch(error){
          message.error('Failed to send invitation: ' + error.message);
        }
    };

    const handleDelete = (userId, record) => {
        console.log(userId, record)
        setCurrentRecord({ ...record, UserId: userId });
        setDeleteModalOpen(true);
      };

    const handleConfirmDelete = async () => {
        console.log(currentRecord)
        // return;
        try {
            const response = await execute("DELETE", `${protectedResources.api.baseEndPoint}/organizations`, {
                orgId: currentRecord.Org_ID});
            if (response.success) {
                alert('User deleted successfully.');
                await fetchData(); // Refetch data after deletion
            } else {
                setError(response.message || 'Failed to delete user');
            }
        } catch (error) {
            alert('Failed to delete user: ' + error.message);
        }
        setDeleteModalOpen(false);
    };

    const fetchData = async () => {
        console.log("fetchData")
        try {
            setLoading(true);
            console.log(getTableParams(tableParams))
            const response = await execute("GET", `${protectedResources.api.baseEndPoint}/organizations?${qs.stringify(getTableParams(tableParams))}`);

            console.log(response)

            if (response.success && response.data.length > 0) {
                console.log("customerData///////////")
                console.log(response.data)
                setCustomers(response.data);
                setTableParams({
                    ...tableParams,
                    pagination: {
                      ...tableParams.pagination,
                      //total: 200,
                      // 200 is mock data, you should read it from server
                        total: response.totalRecords,
                    },
                  });
                setError('');
            } else {
                setCustomers([]);
                setError(response.message || 'No customers found for the specified organization');
            }
            
            setLoading(false);
        } catch (err) {
            console.log(err)
            setError('Failed to fetch data: ' + err.message);
            setLoading(false);
        }
    };
    useEffect(() => {
        console.log(orgId)
        console.log(userDetails)
        fetchData();
        
    }, [ 
        tableParams.pagination?.current,
        tableParams.pagination?.pageSize,
        tableParams?.sortOrder,
        tableParams?.sortField,
        // searchText
        tableParams?.filters,
    ]);

    useEffect(() => {
        console.log("fetching")
        fetchData();
    }, [execute]);

    useEffect(() => {
        if (searchResults) {
            setCustomers(searchResults);
        }
    }, [searchResults]);

    const handleTableChange = (pagination, filters, sorter) => {
        console.log("filters change")
        console.log(filters)
        console.log('handleTableChange', pagination, filters, sorter);

        // Filter out null or empty values
        const cleanedFilters = Object.fromEntries(
            Object.entries(filters).filter(([key, value]) => value && value.length > 0)
        );

        console.log(cleanedFilters)

        setTableParams({
          pagination,
          filters: cleanedFilters,
          sortOrder: Array.isArray(sorter) ? undefined : sorter.order,
          sortField: Array.isArray(sorter) ? undefined : sorter.field,
        });
    
        // `dataSource` is useless since `pageSize` changed
        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
          setCustomers([]);
        }
    };

    

    const handleCloseModalEdit = () => {
        setIsEditModalOpen(false);
      };

    // const expandedRowRender = (record) => (
    //     <RoleValueTable customer={record} />
    // );

    return (
        <>
        <DataContainer>
            <Table
                columns={columns}
                rowKey={record => record.UserID}
                dataSource={customers}
                pagination={tableParams.pagination}
                loading={loading}
                // expandable={{
                //     expandedRowRender,
                //     defaultExpandedRowKeys: ['0'],
                // }}
                onChange={handleTableChange } />      
            
            {/* { isDataEditorOpen ? 
                <DataEditor 
                    isOpen={isDataEditorOpen} 
                    dataItem={editableDataItem} 
                    onDataEditorFinished={handleDataEditFinished} 
                    onDataEditorCancelled={handleDataEditCancelled} /> 
                    : null } */}
                
                <NewOrganizationModal isOpen={modalIsOpen} onClose={toggleModal} onAddOrganization={()=>fetchData} />

                {/* <Modal
                    custDomain={custDomain}
                    isOpen={isDataEditorOpen}
                    onClose={toggleModal}
                    userEmail={userDetails.email}
                    onUserAdded={fetchData} // Pass the onUserAdded prop to the modal
                /> */}

                <EditUserModal isOpen={isEditModalOpen} onClose={handleCloseModalEdit} userData={currentRecord} onSave={handleSaveEditedUser}/>
                
            {isDeleteModalOpen && <DeleteConfirmationModal isOpen={isDeleteModalOpen} onClose={() => setDeleteModalOpen(false)} record={currentRecord} onDeleteConfirmed={handleConfirmDelete} />}
            </DataContainer>
        </>
    );
}

const TableContainer = styled.div`
    border-radius: 0px;
    margin: 20px;
    margin-top:-20px;
    width: 125%;
    margin-left: -150px;
    // max-height: 350px; /* Set maximum height as desired */
    // overflow-y: auto; 
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
`;

const StatusButton = styled.span`
  display: inline-block;
  width: 80px;  // Set a fixed width
  height: 25px; // Set a fixed height
  line-height: 25px; // Use line height equal to height for vertical centering
  color: white;
  background-color: ${props => props.active === 'true' ? '#4CAF50' : '#808080'};  // Grey color for inactive
  padding: 0;
  text-align: center; // Center text horizontally
  border-radius: 5px;
  cursor: default;
`;

const DataContainer = styled.div`
  height: 450px; /* Set your desired height */
  overflow-y: auto; /* Add vertical scrollbar when content overflows */
  margin-left: -155px;
  width:125%;
`;



export default OrgManagementTable;