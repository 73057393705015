
import React, { useState } from 'react';
import SubsPageHeader from './SubsPageHeader';
import SubsPageSearch from "./SubsPageSearch";
import SubsData from "./SubsData";


function Subscriptions({ userDetails }) {
    const [searchResults, setSearchResults] = useState(null); // State to store search results

    return (
        <>
            <SubsPageHeader />
            <SubsPageSearch setSearchResults={setSearchResults} />
            <SubsData searchResults={searchResults} />
        </>
    );
}

export default Subscriptions