import styled from "styled-components";
import useFetchWithMsal from 'hooks/useFetchWithMsal';
import { protectedResources } from 'config/msalConfig';
import React, { useState, useEffect } from 'react';

function DashboardCards() {
    
    const { execute } = useFetchWithMsal({
      scopes: protectedResources.api.scopes.read,
    });
    const [data, setData] = useState([]);
    useEffect(() => {
      async function fetchData() {
        var response = await execute("GET", `${protectedResources.api.baseEndPoint}/dashboard`);
        if (response) {
          if(response.success){
            console.log(response);
            setData(response.data);           
          } else {
            console.log(response);
          }
        }
      }      
      fetchData();      
    }, [execute]);
  
    return (
      <Content>
        {data?.length > 0 
        ? data.map((item, index) => (
          <Card key={index}>
            <CardHeader>{item.title}</CardHeader>
            <CardContent>{item.value}</CardContent>
            <CardFooter href="#">{item.detail}</CardFooter>
          </Card>
        ))
        : <p>No data available</p>
        }
      </Content>
    );
  }

  const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr); // Creates two columns
  gap: 20px; // Spacing between cards
  padding: 20px;
`;

const Card = styled.div`
  background: #FFFFFF;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const CardHeader = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: #4A90E2; // Adjust color according to your theme
`;

const CardContent = styled.div`
  font-size: 40px;
  color: #4A90E2;
  font-weight: bold;
  margin: 10px 0;
`;

const CardFooter = styled.a`
  font-size: 16px;
  color: #4A90E2;
  text-decoration: none;
  cursor: pointer;
`;



  export default DashboardCards;