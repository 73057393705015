import React, { useState } from 'react';
import styled from 'styled-components';
import useFetchWithMsal from 'hooks/useFetchWithMsal';
import { protectedResources } from 'config/msalConfig';
import { useUser } from 'contexts/UserContext';


function NewModuleModal({ isOpen, onClose, fetchData }) {
    const { userDetails } = useUser();
    const [moduleName, setModuleName] = useState('');
    const [short, setAddress] = useState('');
    const [price, setDomain] = useState('');
    const [icon, setIcon] = useState('fa-solid fa-chart-line');
    const [order, setOrder] = useState(100);
    const [successMessage, setSuccessMessage] = useState('');
    const { execute } = useFetchWithMsal({
        scopes: protectedResources.api.scopes.read,
      });
    if (!isOpen) return null;

    const clearForm = () => {
        setModuleName('');
        setAddress('');
        setDomain('');
        setOrder(100);
        setIcon('fa-solid fa-chart-line');
    };

    const handleSubmit = async (event) => {
        event.preventDefault(); // Prevent the default form submission behavior
        console.log({ moduleName, short, price, userDetails, icon, order });
        try {
            var response = await execute("POST", `${protectedResources.api.baseEndPoint}/modules`, {
                moduleName,
                short,
                price,
                userName: userDetails?.name ?? '',
                icon,
                order
            });
            console.log(response);            
            if (response.success) {
                setSuccessMessage('Module created successfully.');
                fetchData(); // Refetch data after adding new module
                setTimeout(() => {
                    clearForm();
                    onClose();
                }, 2000); // Adjust the delay as needed (2000 milliseconds = 2 seconds)
            } else {
                throw new Error(response.message || 'Failed to create module');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <>
            {isOpen && (
                <>
                    <Overlay onClick={onClose} />
                    <Modal>
                        <h2>New Module</h2>
                        {successMessage && <div className="success-message">{successMessage}</div>}
                        <ModalForm onSubmit={handleSubmit}>
                            <FormLabel>
                                Module Name
                                <Input
                                    type="text"
                                    value={moduleName}
                                    onChange={e => setModuleName(e.target.value)}
                                    placeholder="Type in"
                                />
                            </FormLabel>
                            <FormLabel>
                                Short Name
                                <Input
                                    type="text"
                                    value={short}
                                    onChange={e => setAddress(e.target.value)}
                                    placeholder="Type in"
                                />
                            </FormLabel>
                            <FormLabel>
                                Price
                                <Input
                                    type="text"
                                    value={price}
                                    onChange={e => setDomain(e.target.value)}
                                    placeholder="Price in $"
                                />
                            </FormLabel>
                            <FormLabel>
                                Icon
                                <Input
                                    type="text"
                                    value={icon}
                                    onChange={e => setIcon(e.target.value)}
                                    placeholder="Font Awesome icon"
                                />
                            </FormLabel>
                            <FormLabel>
                                Display Order
                                <Input
                                    type="number"
                                    value={order}
                                    onChange={e => setOrder(e.target.value)}
                                    placeholder="Order no to appear in sidebar"
                                />
                            </FormLabel>
                            <ButtonsRow>
                                <Button type="submit">Submit</Button>
                                <Button type="button" onClick={onClose}>Close</Button>
                            </ButtonsRow>
                        </ModalForm>
                    </Modal>
                </>
            )}
        </>
    );
}


const Modal = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 30px;
    z-index: 2;
    width: 80%;
    max-width: 600px;
    border: 1px solid #ccc;
    margin-bottom: 100px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
`;

const ModalForm = styled.form`
    display: flex;
    flex-direction: column;
    gap: 20px; // Spacing between form fields
`;

const FormLabel = styled.label`
    display: block;
    margin-bottom: 8px;
    color: #333;
`;

const Input = styled.input`
    padding: 8px 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%; // Ensures input fields expand to full container width
`;

const Button = styled.button`
    padding: 10px 20px;
    font-size: 16px;
    background-color: #f60; // Orange background
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;

    &:hover {
        background-color: #e55; // Darker orange on hover
    }
`;

const ButtonsRow = styled.div`
    display: flex;
    justify-content: center;  // Centers the buttons horizontally
    gap: 10px;                // Spacing between buttons
`;


export default NewModuleModal;