import React, { useState, useEffect } from 'react';
import useFetchWithMsal from 'hooks/useFetchWithMsal';
import { protectedResources } from 'config/msalConfig';
import UserPageHeader from './UserPageHeader';
import UserPageSearch from './UserPageSearch';
import UserData from './UserData';


function UserManager({ userDetails }) {
  const [searchResults, setSearchResults] = useState(null);
  const { execute } = useFetchWithMsal({
    scopes: protectedResources.api.scopes.read,
  });
  const fetchRecords = async () => {
    console.log('Fetching data...');
    try {
      const response = await execute('GET', `${protectedResources.api.baseEndPoint}/adminUsers`);
      if(response){
        if (response.success) {
          setSearchResults(response.data);
        } else {
          console.log('Error fetching data:', response.error);
        }        
      }else{
        console.log('response is undefined', response);
      }

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchRecords();
  }, [execute]);

  useEffect(() => { 
      console.log('searchResults:', searchResults);
  }, [searchResults]);

  return (
    <>
      <UserPageHeader fetchRecords={fetchRecords} />
      <UserPageSearch setSearchResults={setSearchResults} />
      <UserData searchResults={searchResults} fetchRecords={fetchRecords} />
    </>
  );
}

export default UserManager;