import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useUser } from "contexts/UserContext";
import { FaBoxesStacked, FaFileInvoiceDollar, FaNetworkWired, FaUsers, FaWindows } from "react-icons/fa6";


const menuItems = [
    {
      title: "Dashboard",
      icon: <FaWindows />,
      activeIcon: <FaWindows />,
      isActive: true,
    },
    {
      title: "Organization Management",
      icon: <FaNetworkWired />,
      activeIcon: "https://cdn.builder.io/api/v1/image/assets/TEMP/e0d5114d4f088d39d7c00afca02d59b90b59b8249cd76e5b44ab05d4e86f0571?apiKey=1607f544a0344092815fcb145a04f0bb&",
    },
    {
      title: "Module Management",
      icon: <FaBoxesStacked />,
      activeIcon: <FaBoxesStacked />,
    },
    {
      title: "Customer Role Management",
      icon: <FaBoxesStacked />,
      activeIcon: <FaBoxesStacked />,
    },
    {
      title: "Subscriptions",
      icon: <FaFileInvoiceDollar />,
      activeIcon: <FaFileInvoiceDollar />,
    },
    {
      title: "User Management",
      icon: <FaUsers />,
      activeIcon: <FaUsers />,
    },
];
  

const generalItems = [
    {
      title: "App Settings",
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/0235d0d580d575bd0f4d170055010d9cc0677cab46726c3c7cbf127a7cc63c33?apiKey=1607f544a0344092815fcb145a04f0bb&",
      activeIcon: "https://cdn.builder.io/api/v1/image/assets/TEMP/e0d5114d4f088d39d7c00afca02d59b90b59b8249cd76e5b44ab05d4e86f0571?apiKey=1607f544a0344092815fcb145a04f0bb&",
    },
    {
      title: "Workspace",
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/327ba1e01d74c8a2f069ce5c6319910109430484e239e0fc3b9376a207977098?apiKey=1607f544a0344092815fcb145a04f0bb&",
      activeIcon: "https://cdn.builder.io/api/v1/image/assets/TEMP/42e91975d30d86a518ce25caa4d5b6ddfc889beddbfd8520c7c3b913923ae49a?apiKey=1607f544a0344092815fcb145a04f0bb&",
    },
];

const Sidebar = () => {
    const navigate = useNavigate();  
    const { userDetails } = useUser();
    
    
    const handleMenuItemClick = (title) => {
        const specialRoutes = {
          "Dashboard":"/dashboard",

            "Organization Management": "/organization-management",
          "Module Management": "/module-management",
          "Subscriptions": "/subscriptions",
          "User Management": "/manageUsers",
          "Customer Role Management": "/manageCustomerRoles",
          // Add more special titles and routes here as needed
        };
      
        // Check if the clicked title is a special title
        const specialRoute = specialRoutes[title];
      
        if (specialRoute) {
          navigate(specialRoute); // Navigate to the special route if it exists
        } else {
          navigate(""); // Default navigation logic for regular items
        }
      };

    return (
        <SidebarWrapper>
          <SidebarHeader>
            <SidebarLogo src="https://cdn.builder.io/api/v1/image/assets/TEMP/1693daa69f399b0264e2363704c7f8661c830c9d2df73b95da0990e74078e70d?apiKey=1607f544a0344092815fcb145a04f0bb&" alt="Product Administration" />
            <SidebarTitle>Product Administration</SidebarTitle>
          </SidebarHeader>
          <SidebarSection>
            {menuItems.map((item, index) => (
              <SidebarItem  key={index}
              active={item.active}
              onClick={() => handleMenuItemClick(item.title)}>
                {item.icon}
                {/* <SidebarIcon
                  src={item.active ? item.activeIcon : item.icon}
                  alt={item.title}
                /> */}
                <SidebarItemTitle>{item.title}</SidebarItemTitle>
                {/* <SidebarItemIcon
                  src={item.active ? item.activeIcon : item.icon}
                  alt={item.title}
                /> */}
              </SidebarItem>
            ))}
          </SidebarSection>
          <SidebarSection>
            <SidebarSectionTitle>
              <span>GENERAL</span>
            </SidebarSectionTitle>
            {generalItems.map((item, index) => (
              <SidebarItem key={index} >
           
                <SidebarIcon src={item.icon} alt={item.title} />
                <SidebarItemTitle>{item.title}</SidebarItemTitle>
                <SidebarItemIcon src={item.activeIcon} alt={item.title} />
              </SidebarItem>
              
            ))}
            
          </SidebarSection>
        </SidebarWrapper>
    );
};
const SidebarWrapper = styled.aside`
background-color: var(--bicollab-primary-color-blue-900, #002c57);
color: var(--bicollab-black-white-black-300, #bbbbbc);
display: flex;
flex-direction: column;
font-size: 14px;
font-weight: 500;
max-width: 424px;
padding: 16px 20px;

`;

const SidebarHeader = styled.header`
color: var(--bicollab-primary-color-blue-300, #4a9ecf);
display: flex;
font-size: 20px;
gap: 9px;
`;

const SidebarLogo = styled.img`
aspect-ratio: 1;
object-fit: auto;
object-position: center;
width: 53px;
`;

const SidebarTitle = styled.h1`
font-family: Ubuntu, sans-serif;
margin: auto 0;
overflow: hidden;
text-overflow: ellipsis;
white-space: nowrap;
font-size: 24px;
`;

const SidebarDivider = styled.img`
border: 1px solid var(--bicollab-black-white-black-300, #bbbbbc);
margin-top: 25px;
object-fit: auto;
object-position: center;
stroke-width: 1px;
width: 100%;
`;

const SidebarSection = styled.section`
display: flex;
flex-direction: column;
gap: 9px;
margin-top: 26px;
`;

const SidebarItem = styled.div`
align-items: center;
background-color: ${(props) => props.active ? "#FFFFFF" : "transparent"};  // White when active
border: 1px solid ${(props) => props.active ? "var(--bicollab-secondary-color-orange-700, #f16622)" : "transparent"};
border-radius: ${(props) => (props.active ? "2px" : "6px")};
color: ${(props) => props.active ? "var(--bicollab-secondary-color-orange-700, #f16622)" : "inherit"};
display: flex;
gap: 7px;
justify-content: space-between;
padding: 8px 0 8px 11px;
white-space: nowrap;
`;


const SidebarIcon = styled.img`
aspect-ratio: 1;
object-fit: auto;
object-position: center;
width: 24px;
`;

const SidebarItemTitle = styled.span`
flex: 1;
font-family: Ubuntu, sans-serif;
margin: auto 0;
color: var(--bicollab-black-white-black-300, #bbbbbc);
`;

const SidebarItemIcon = styled.img`
aspect-ratio: 1;
object-fit: auto;
object-position: center;
width: 24px;
`;

const SidebarSectionTitle = styled.div`
color: var(--bicollab-black-white-black-300, #bbbbbc);
display: flex;
font-size: 12px;
gap: 3px;
padding: 3px 1px;
white-space: nowrap;

span {
  flex-grow: 1;
  font-family: Ubuntu, sans-serif;
}
`;

const SidebarSectionDivider = styled.img`
border: 1px solid var(--bicollab-primary-color-blue-500, #007ab9);
margin: auto 0;
object-fit: auto;
object-position: center;
stroke-width: 1px;
width: 100%;
`;
export default Sidebar;