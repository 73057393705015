import React from 'react';
import styled from 'styled-components';


function SubsPageHeader() {
    return (
      <OutsideContainer>
        <TitleContainer>
          <Title>Subscriptions</Title>
        </TitleContainer>
      </OutsideContainer>            
    );
  }

  
  const OutsideContainer = styled.div`
  padding: 10px;
  border-radius: 0px;
  margin: 20px;
  width: 130%;
  margin-left: -150px;
  padding-bottom:5px;
`;

const TitleContainer = styled.div`
position: sticky; /* Make the container sticky */
top: 0; /* Stick it to the top */
z-index: 1; /* Ensure it stays above the scrolling content */
display: flex;
justify-content: space-between;
align-items: center;
padding: 10px 20px; /* Adjust padding as needed */

  margin-right: -10px;
  padding-left: 1px;
`;


const Title = styled.h1`
margin: 0;
font-size: 34px;
font-family: Ubuntu, sans-serif;
color: var(--bicollab-primary-color-blue-900, #002c57);
`;

const AddButton = styled.button`
padding: 10px 18px;
font-size: 20px;
font-family: Ubuntu, sans-serif;
color: var(--bicollab-black-white-white, #fff);
background-color: var(--bicollab-secondary-color-orange-700, #f16622);
border: none;
border-radius: 4px;
cursor: pointer;
`;

export default SubsPageHeader;