import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import useFetchWithMsal from 'hooks/useFetchWithMsal';
import { protectedResources } from 'config/msalConfig';
import DeleteConfirmationModal from 'components/shared/DeleteConfirmationModal';
import { FaFilePen, FaTrash } from 'react-icons/fa6';
import EditModuleModal from './EditModuleModal';


function formatDate(dateString) {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US');  // Adjust 'en-US' as needed for your locale
}


function ModuleData({ searchResults }) {
    const [modules, setModules] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [records, setRecords] = useState(searchResults || []);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [currentRecord, setCurrentRecord] = useState(null);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const { execute } = useFetchWithMsal({
        scopes: protectedResources.api.scopes.read,
      });

    const handleEdit = (record) => {
        setCurrentRecord(record);
        setIsEditModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsEditModalOpen(false);
    };

    const handleSaveEditedUser = async (updatedRecord) => {
        await fetchData(); // Refetch data after editing
        handleCloseModal(); // Close the modal after saving
    };

    const handleDelete = (Org_ID, record) => {
        setCurrentRecord({ ...record, Org_ID: Org_ID });
        setDeleteModalOpen(true);
    };

    const handleConfirmDelete = async () => {
        try {
            var response = await execute("DELETE", `${protectedResources.api.baseEndPoint}/modules/${currentRecord.ModuleName}`);
            if(response){
                if (response.success) {
                    alert('Module deleted successfully.');
                    await fetchData(); // Refetch data after deletion
                } else {
                    throw new Error(response.message);
                }
            }
        } catch (error) {
            alert('Failed to delete: ' + error.message);
        }
        setDeleteModalOpen(false);
    };


    const fetchData = async () => {
        try {
            var response = await execute("GET", `${protectedResources.api.baseEndPoint}/modules`);
            if(response){
                if(response.success){
                    setModules(response.data);
                    setRecords(response.data);
                }else{
                    setError('Failed to fetch data: ' + response.message);
                }
            }
        } catch (err) {
            setError('Failed to fetch data: ' + err.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [execute]);

    useEffect(() => {
        if (searchResults) {
            setRecords(searchResults);
        }
    }, [searchResults]);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error}</p>;

    return (
        <TableContainer>
            <Table>
                <thead>
                    <TableRow>
                        <TableHeader>CreatedDate</TableHeader>
                        <TableHeader>Module Name</TableHeader>
                        <TableHeader>ShortName</TableHeader>
                        <TableHeader>Price</TableHeader>
                        <TableHeader>Subscription #</TableHeader>
                        <TableHeader>Icon</TableHeader>
                        <TableHeader>Display Order</TableHeader>
                        <TableHeader>Added By</TableHeader>
                        <TableHeader>Actions</TableHeader>
                    </TableRow>
                </thead>
                <tbody>
                    {records.map((module, index) => (
                        <TableRow key={index}>
                            <TableCell>{formatDate(module.CreatedOn)}</TableCell>
                            <TableCell>{module.ModuleName}</TableCell>
                            <TableCell>{module.ShortName}</TableCell>
                            <TableCell>{module.Price}</TableCell>
                            <TableCell>{module.SubscribedNumber}</TableCell>
                            <TableCell>{module.Icon}</TableCell>
                            <TableCell>{module.Order}</TableCell>
                            <TableCell>{module.AddedBy}</TableCell>
                            <TableCell>
                                <ActionButton onClick={(e) => {
                                    e.stopPropagation();
                                    handleEdit(module);
                                }}>
                                    <FaFilePen style={{ color: '#ffad1c' }}/>
                                </ActionButton>
                                <ActionButton onClick={(e) => {
                                    e.stopPropagation();
                                    handleDelete(module.Org_ID, module);
                                }}>
                                    <FaTrash style={{ color: '#ffad1c' }}/>
                                </ActionButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </tbody>
                {isEditModalOpen && <EditModuleModal isOpen={isEditModalOpen} onClose={handleCloseModal} userData={currentRecord} onSave={handleSaveEditedUser}/>}
                {isDeleteModalOpen && <DeleteConfirmationModal isOpen={isDeleteModalOpen} onClose={() => setDeleteModalOpen(false)} record={currentRecord} onDeleteConfirmed={handleConfirmDelete} />}
            </Table>
        </TableContainer>
    );
}

const TableContainer = styled.div`
    background-color: #f8f8f8;
    border-radius: 0px;
    margin: 20px;
    width: 130%;
    margin-left: -150px;
    max-height: 500px; /* Set maximum height as desired */
    overflow-y: auto; 
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
`;

const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
`;

const TableHeader = styled.th`
    background-color: #f2f2f2;
    color: black;
    font-weight: bold;
    padding: 12px 15px;
    text-align: left;
`;

const TableRow = styled.tr`
    &:nth-child(odd) {
        background-color: #f2f2f2;
    }
    &:nth-child(even) {
        background-color: #FFFFFF;
    }
`;

const TableCell = styled.td`
    padding: 20px 15px;
    border-bottom: 1px solid #ddd;
`;

const Icon = styled.img`
    width: 20px;
    height: 20px;
    margin-right: 5px;
    cursor: pointer;
`;

const ActionButton = styled.button`
    background: none;
    border: none;
    cursor: pointer;
    padding: 5px;
    margin: 0 2px;
    display: inline-flex;
    align-items: center;
`;

const ActionSeparator = styled.span`
    margin: 0 10px;
    color: #ccc;
`;

export default ModuleData;