import * as React from "react";
import styled from "styled-components";

const footerData = [
  {
    text: "Terms of Service",
    url: "/terms-of-service",
  },
  {
    text: "Privacy Policy",
    url: "/privacy-policy",
  },
];

function Footer() {
  return (
    <FooterWrapper>
      <FooterContent>
        <Copyright>© The BI Collaborative. All rights reserved.</Copyright>
        <Logo src="https://cdn.builder.io/api/v1/image/assets/TEMP/f7b67f19e44c17f731686f76a339d113e6e211a645112d23e277cf92ea3352e7?apiKey=1607f544a0344092815fcb145a04f0bb&" alt="The BI Collaborative Logo" />
        <FooterLinks>
          {footerData.map((link, index) => (
            <FooterLink key={index} href={link.url}>
              {link.text}
            </FooterLink>
          ))}
        </FooterLinks>
      </FooterContent>
    </FooterWrapper>
  );
}

const FooterWrapper = styled.footer`
  box-shadow: 0px -2px 20px 0px rgba(0, 0, 0, 0.15);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  font-weight: 500;
  text-align: center;
  justify-content: center;
`;

// const FooterContent = styled.div`
//   background-color: var(--bicollab-black-white-white, #fff);
//   display: flex;
//   width: 100%;
//   align-items: center;
//   gap: 20px;
//   padding: 6px 28px;

//   @media (max-width: 991px) {
//     max-width: 100%;
//     flex-wrap: wrap;
//     padding-left: 20px;
//   }
// `;

// const Copyright = styled.p`
//   color: var(--bicollab-black-white-black-400, #767676);
//   align-self: stretch;
//   flex-grow: 1;
//   flex-basis: auto;
//   margin: auto 0;
//   font: 12px Ubuntu, sans-serif;
// `;

// const Logo = styled.img`
//   aspect-ratio: 1;
//   object-fit: contain;
//   width: 55px;
//   align-self: stretch;
// `;

// const FooterLinks = styled.nav`
//   align-self: stretch;
//   display: flex;
//   gap: 16px;
//   font-size: 14px;
//   color: var(--bicollab-black-white-black-700, #1c1c1c);
//   justify-content: space-between;
//   margin: auto 0;
//   padding: 10px;
// `;

const FooterLink = styled.a`
  font-family: Ubuntu, sans-serif;
`;

const FooterContent = styled.div`
  background-color: var(--bicollab-black-white-white, #fff);
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;  /* Ensures spacing on both ends and middle */
  padding: 6px 28px;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: center; /* Aligns items vertically for smaller screens */
  }
`;

const Copyright = styled.p`
  color: var(--bicollab-black-white-black-400, #767676);
  flex: 1;  /* Allows this element to grow to take necessary space */
  text-align: left;  /* Aligns the text to the left within the flexed item */
  margin: 0; /* Removes margin for alignment purposes */
  font: 12px Ubuntu, sans-serif;
`;

const Logo = styled.img`
  order: -1;  /* Ensures the logo is always in the center */
  object-fit: contain;
  width: 55px;
`;

const FooterLinks = styled.nav`
  flex: 1;  /* Similar to copyright, allows this element to grow */
  display: flex;
  gap: 16px;
  font-size: 14px;
  color: var(--bicollab-black-white-black-700, #1c1c1c);
  justify-content: flex-end; /* Aligns links to the right */
  margin: 0; /* Adjusts margin for alignment */
  padding: 10px;
`;

export default Footer;