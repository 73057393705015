import { LogLevel } from '@azure/msal-browser';

export const b2cPolicies = {
  names: {
      signIn: 'B2C_1A_SIGNIN',
      forgotPassword: 'B2C_1_reset',
      editProfile: 'B2C_1_edit_profile',
  },
  authorities: {
      signIn: {
          authority: 'https://bicollab.b2clogin.com/bicollab.onmicrosoft.com/B2C_1A_SIGNIN',
      },
      forgotPassword: {
          authority: 'https://bicollab.b2clogin.com/bicollab.onmicrosoft.com/B2C_1_reset',
      },
      editProfile: {
          authority: 'https://bicollab.b2clogin.com/bicollab.onmicrosoft.com/b2c_1_edit_profile',
      },
  },
  authorityDomain: 'bicollab.b2clogin.com',
};

export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_AB2C_AUTH_CLIENTID,
    authority: b2cPolicies.authorities.signIn.authority, 
    knownAuthorities: [b2cPolicies.authorityDomain],
    redirectUri: `${window.location.origin}/redirect`,
    postLogoutRedirectUri: '/', // Indicates the page to navigate after logout.
    navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
    // scopes: process.env.REACT_APP_AB2C_AUTH_SCOPES.split(','),
    // validateAuthority: false,
  },
  cache: {
    cacheLocation: 'localStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      }
    }
  },
};

export const protectedResources = {
  api: {
      baseEndPoint: process.env.REACT_APP_API_BASE_URL,
      scopes: {
          read: ['https://BICollab.onmicrosoft.com/8e36a5ea-6652-40b3-a8f1-1101407b707a/bic.read'],
      },
  },
};


export const loginRequest = {
  scopes: [...protectedResources.api.scopes.read],
};

