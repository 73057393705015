import React from 'react';
import DashboardCards from './DashboardCards';
import LastActionTaken from './LastActionTaken';

function Dashboard({ userDetails }) {
    
  return (
    <>
          <DashboardCards />
          <LastActionTaken />
    </>
  );
};

export default Dashboard;


