import React, { useState } from 'react';
import ModulePageHeader  from './ModulePageHeader';
import ModulePageSearch from './ModulePageSearch';
import ModuleData from './ModuleData';


function AppLayoutModuleManage({ userDetails }) {
    const [searchResults, setSearchResults] = useState(null); // State to store search results

    return (
        <>
            <ModulePageHeader setSearchResults={setSearchResults}/>
            <ModulePageSearch setSearchResults={setSearchResults} />
            <ModuleData searchResults={searchResults} />
        </>
    );
}

export default AppLayoutModuleManage;
