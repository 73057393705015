
import useFetchWithMsal from '../../hooks/useFetchWithMsal';
import { protectedResources } from '../../config/msalConfig';

// export default EditAdmin;
import React from 'react';
import styled from 'styled-components';

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
`;

const Modal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 30px;
  z-index: 2;
  width: 80%;
  max-width: 600px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const ButtonsRow = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
`;

const Button = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  background-color: #f60;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
  &:hover {
    background-color: #e55;
  }
`;


const EditAdmin = ({ isOpen, onClose, record, onDeleteConfirmed }) => {
  const { error, execute } = useFetchWithMsal({
    scopes: protectedResources.api.scopes.read,
  });

  if (!isOpen) return null;

  const handleToggleActivate = async () => {
    try {
      execute("PUT", `${protectedResources.api.baseEndPoint}/adminUsers`,{...record, isActive: !record.isActive}).then((response) => {
        if (response.success) {
          onDeleteConfirmed(record);
          onClose();
        } else {
          alert('Failed to update status');
        }
      });
    } catch (error) {
      console.error('Error updating status:', error);
      alert('Error updating status');
    }
  };

  return (
    <>
      <Overlay onClick={onClose} />
      <Modal>
        <h2>Edit Admin</h2>
        <p>Current Status: {record.isActive ? 'Active' : 'Inactive'}</p>
        <ButtonsRow>
          <Button onClick={handleToggleActivate}>
            {record.isActive ? 'Deactivate' : 'Activate'}
          </Button>
          <Button onClick={onClose}>Close</Button>
        </ButtonsRow>
      </Modal>
    </>
  );
};

export default EditAdmin;
