import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import useFetchWithMsal from '../../hooks/useFetchWithMsal';
import { protectedResources } from '../../config/msalConfig';


// Styled components definitions (assuming they are similar to the previous ones)

function EditModuleModal({ isOpen, onClose, userData, onSave }) {
    const [moduleData, setModuleData] = useState({});
    const [originalModuleData, setOriginalModuleData] = useState({});
    const { execute } = useFetchWithMsal({
      scopes: protectedResources.api.scopes.read,
    });

    useEffect(() => {
        if (isOpen) {
            setModuleData(userData);
            setOriginalModuleData(userData);
        }
    }, [userData, isOpen]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setModuleData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Prepare a list of fields that have been modified
        const modifiedFields = Object.keys(moduleData).filter((key) => moduleData[key] !== originalModuleData[key]);
        if (modifiedFields.length === 0) {
            alert('No changes detected');
            return;
        }
        try {
            const response = await execute('PUT', `${protectedResources.api.baseEndPoint}/modules`, {
                ...moduleData,
                originalModuleData, // assuming there is a ModuleId
                modifiedFields 
            });
            if (response.success) {
                onSave(moduleData);
                onClose();
                alert('Module data updated successfully!');
            } else {
                throw new Error('Failed to update module data');
            }
        } catch (error) {
            console.error('Failed to update module:', error);
            alert('Error updating module: ' + error.message);
        }
    };

    if (!isOpen) return null;

    return (
        <ModalOverlay>
            <ModalContainer>
                <ModalHeader>Edit Module</ModalHeader>
                <ModalForm onSubmit={handleSubmit}>
                    <FormLabel htmlFor="ModuleName">Module Name</FormLabel>
                    <FormInput
                        type="text"
                        id="ModuleName"
                        name="ModuleName"
                        value={moduleData.ModuleName || ''}
                        onChange={handleChange}
                    />

                    

                    <FormLabel htmlFor="Price">Price</FormLabel>
                    <FormInput
                        type="text"
                        id="Price"
                        name="Price"
                        value={moduleData.Price || ''}
                        onChange={handleChange}
                    />
                                        
                    <FormLabel htmlFor="Icon">Icon</FormLabel>
                    <FormInput
                        type="text"
                        id="Icon"
                        name="Icon"
                        value={moduleData.Icon || ''}
                        onChange={handleChange}
                    />      

                    <FormLabel htmlFor="Order">Order</FormLabel>
                    <FormInput
                        type="number"
                        id="Order"
                        name="Order"
                        value={moduleData.Order || ''}
                        onChange={handleChange}
                    />

                    <ButtonsRow>
                        <Button type="submit">Save Changes</Button>
                        <Button type="button" onClick={onClose}>Cancel</Button>
                    </ButtonsRow>
                </ModalForm>
            </ModalContainer>
        </ModalOverlay>
    );
}


// Styled components for the modal
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1050;
`;

const ModalContainer = styled.div`
  background: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 500px;
  z-index: 1051;
`;

const ModalHeader = styled.h2`
  margin-top: 0;
`;

const ModalForm = styled.form`
  display: flex;
  flex-direction: column;
`;

const FormLabel = styled.label`
  margin-top: 10px;
`;

const FormInput = styled.input`
  padding: 8px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const ButtonsRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

const Button = styled.button`
  padding: 10px 20px;
  border-radius: 4px;
  border: none;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  &:hover {
    background-color: #0056b3;
  }

  &:first-child {
    background-color: #6c757d;
    &:hover {
      background-color: #5a6268;
    }
  }
`;


const CheckboxInput = styled.input.attrs({ type: 'checkbox' })`
  margin-top: 5px;
`;

const TextareaInput = styled.textarea`
  padding: 8px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
`;


export default EditModuleModal;