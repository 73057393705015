import styled from "styled-components";
import { useState } from 'react';
import useFetchWithMsal from 'hooks/useFetchWithMsal';
import { protectedResources } from 'config/msalConfig';
import { Col, Row } from "antd";


function OrgCustPageSearch({ setSearchResults }) {
    const [organization, setOrganization] = useState('');
    const [customer, setCustomer] = useState('');
    const { execute } = useFetchWithMsal({
      scopes: protectedResources.api.scopes.read,
    });

    const handleSearch = () => {
        console.log('Sending search parameters:', { organization, customer });

        execute('GET', `${protectedResources.api.baseEndPoint}/customers?${new URLSearchParams({
                organization,
                customer
            })}`)
            .then(response => {
                setSearchResults(response.data);
                console.log('Search Results:', response.data);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    };

    const handleOrganizationChange = (e) => {
        console.log('Event object:', e);
        console.log('Customer input:', e.target.value);
        setOrganization(e.target.value);
    };

    const handleCustomerChange = (e) => {
        console.log('Event object:', e);
        console.log('Customer input:', e.target.value);
        setCustomer(e.target.value);
    };

    return (
        <SearchParameterContainer>
            <SearchParameterTitle>Search Parameter</SearchParameterTitle>
            <Row gutter={10}>
                <Col className="gutter-row" span={11}>
                    <InputField
                        label="Customer"
                        placeholder="Enter a name or email address"
                        value={organization}
                        onChange={handleOrganizationChange}
                    />                
                </Col>
                <Col className="gutter-row" span={11}>
                    <InputField
                        label="Workspace"
                        placeholder="Enter a workspace name"
                        value={customer}
                        onChange={handleCustomerChange}
                    />
                </Col>
                <Col className="gutter-row" span={2}>
                    <SearchButton onClick={handleSearch}>Search</SearchButton>
                </Col>
            </Row>
        </SearchParameterContainer>
    );
}

function InputField({ label, placeholder, value, onChange }) {
    return (
        <InputFieldContainer>
            <InputFieldLabel>{label}</InputFieldLabel>
            <InputFieldWrapper
                type="text"  // Specify the type if necessary, or make it a prop if you need different types
                placeholder={placeholder}
                value={value}  // Bind the input value to the value prop
                onChange={onChange}  // Propagate the onChange event to the parent component
            />
        </InputFieldContainer>
    );
}



const InputFieldLabel = styled.label`
    color: #767676;
    text-align: left;
    font: 500 14px Ubuntu, sans-serif;
`;

const InputFieldWrapper = styled.input`
    border-radius: 2px;
    border: 1px solid rgba(187, 187, 188, 1);
    background-color: var(--bicollab-black-white-white, #fff);
    margin-top: 13px;
    padding: 10px 21px;
    color: var(--bicollab-black-white-black-300, #bbbbbc);
    font: 400 16px Ubuntu, sans-serif;
    
    @media (max-width: 991px) {
        padding: 0 20px;
    }
`;

const InputFieldContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 0 5px;
`;

const SearchParameterContainer = styled.section`
    padding: 20px;
    background-color: #f8f8f8;
    border-radius: 0px;
    margin: 20px;
    width: 125%;
    margin-left: -150px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    margin-top:-20px;
`;

const SearchParameterTitle = styled.h2`
    color: var(--bicollab-primary-color-blue-900, #002c57);
    letter-spacing: 0.4px;
    font: 500 16px/100% Ubuntu, sans-serif;
    
    @media (max-width: 991px) {
        max-width: 100%;
    }
`;

const SearchParameterFields = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    margin-top: 24px;
`;

const SearchButton = styled.button`
    border-radius: 4px;
    background-color: var(--bicollab-secondary-color-orange-700, #f16622);
    align-self: flex-end;
    margin-top: 28px;
    padding: 10px 18px;
    color: var(--bicollab-black-white-white, #fff);
    white-space: nowrap;
    font: 500 20px Ubuntu, sans-serif;
    
    @media (max-width: 991px) {
        white-space: initial;
        padding: 0 20px;
    }
`;
export default OrgCustPageSearch;